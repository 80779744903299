<template>
    <div class="header-bg">{{title}}</div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            title: this.$route.meta.title
        }
    }
}
</script>

<style lang="less">
.header-bg {
    height: 40px;
    line-height: 40px;
    text-align: center;
}
</style>