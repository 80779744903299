import Vue from 'vue';
import axios from 'axios'
import { Toast, Dialog } from 'vant';
Vue.use(Toast);

import store from '@/store'
// import { getToken } from '@/utils/auth'
axios.defaults.withCredentials = true

/**
 *
 */
// 添加loading效果
function startLoading() {
    store.commit('showLoading')
}

function endLoading() {
    store.commit('hideLoading')
}

console.log(process.env)
console.log(process.env.VUE_APP_BASE_API)
console.log('/api')
// create an axios instance
// const baseURL = process.env.NODE_ENV === 'development' ? 'http://testdirectapi.miaocang.cc:8086' : process.env.VUE_APP_BASE_API
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASE_API,
//   baseURL,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // console.log(config)
    // Do something before request is sent
    if (store.getters.token) {
      // 让每个请求携带token-- ['X-Mall-Admin-Token']为自定义key 请根据实际情况自行修改
    //   config.headers['X-Mall-Admin-Token'] = getToken()
    }
    // if (config.url !== '/profile/nnotice' && config.url !== '/mall/order/orderInfoForConfirm') {
      startLoading()
    // }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // if (response.config.url !== '/profile/nnotice') {
      endLoading()
    // }
    const res = response.data

    if (res.errno === 501) {
        Dialog({ title: '错误', message: '系统未登录，请重新登录' }).then(() => {
            store.dispatch('FedLogOut').then(() => {
                location.reload()
            })
        });
      return Promise.reject('error')
    } else if (res.errno === 502) {

      Dialog({ title: '错误', message: '系统内部错误，请联系管理员维护' }).then(() => {});
      return Promise.reject('error')
    } else if (res.errno === 503) {
        
      Dialog({ title: '警告', message: '请求业务目前未支持' }).then(() => {});
      return Promise.reject('error')
    } else if (res.errno === 504) {
        
      Dialog({ title: '警告', message: '更新数据已经失效，请刷新页面重新操作' }).then(() => {});
      return Promise.reject('error')
    } else if (res.errno === 505) {
        
      Dialog({ title: '警告', message: '更新失败，请再尝试一次' }).then(() => {});
      return Promise.reject('error')
    } else if (res.errno === 506) {
        
      Dialog({ title: '错误', message: '没有操作权限，请联系管理员授权' }).then(() => {});
      return Promise.reject('error')
    } else if (res.errno !== 0) {
      // 非5xx的错误属于业务错误，留给具体页面处理
      return Promise.reject(response)
    } else {
      return response
    }
  }, error => {
    console.log('err' + error)// for debug
    Toast('登录连接超时（后台不能连接，请联系系统管理员）')
    return Promise.reject(error)
  })

export default service
