import request from './request'

// H5-订单详情
export function orderDetails(query) {
  return request({
    url: '/wx/h5/orderDetail',
    method: 'get',
    params: query
  })
}
// H5-商品详情
export function goodsDetails(query) {
    return request({
      url: '/wx/h5/goodsDetail',
      method: 'get',
      params: query
    })
}
// H5-装货信息
export function orderShipListByOid(query) {
  return request({
    url: '/wx/h5/orderShipListByOid',
    method: 'get',
    params: query
  })
}

// demo
// export function addCart(data) {
//   return request({
//     url: '/mall/cart/add',
//     method: 'post',
//     data
//   })
// }



