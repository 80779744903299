<template>
  <div class="load">
    <Header v-show="UA !== 'micromessenger'"></Header>
    <van-collapse v-model="activeNames">
      <van-collapse-item
        v-for="(item, index) in loadGoodsData"
        :key="index"
        :name="index"
      >
        <template #title>
          <div class="load-item">
            <div class="address-title">
              <img class="icon-load" :src="icon_load" alt="icon_load" />
              <div>{{ item.loadingAddress }}</div>
              <div class="to-load" @click="OpenMap(item)">
                <div>去装货</div>
                <img class="icon-go" :src="icon_go" alt="icon_go" />
              </div>
            </div>
            <div class="u-info">
              <div>商家：{{ item.warehouseName }}</div>
              <div>联系人：{{ item.warehouseAdmin }}</div>
              <div>联系电话：{{ item.warehouseAdminTel }}</div>
            </div>
          </div>
        </template>
        <div class="goods-list">
          <div
            class="goods-item"
            v-for="(item2, index2) in item.orderShipGoodsVoList"
            :key="index2"
            @click="preview(item2.gallery)"
          >
            <img class="pic" :src="item2.picUrl" alt="picUrl" />
            <div class="info">
              <div class="title">
                <span class="name">{{ item2.goodsName }}</span>
                <span class="count">×{{ item2.shipQuantity }}</span>
              </div>
              <div class="sku">
                <span>规格：</span>
                <span
                  style="padding-right: 0.3125rem"
                  v-for="(item3, index3) in item2.goodsSpecificationList"
                  :key="index3"
                  >{{
                    item3.specification +
                    item3.value +
                    "~" +
                    item3.valueEnd +
                    item3.unit
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="amount">总数量：{{ item.goodsCount }}件</div>
      </van-collapse-item>
    </van-collapse>
    <van-action-sheet
      v-model="showAppList"
      :actions="actions"
      @select="onSelectApp"
    />
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import icon_load from "@/assets/icon/icon-charge@2x.png";
import icon_go from "@/assets/icon/icon-position@2x.png";
import { orderShipListByOid } from "@/api/goodsShare";
import Header from "@/components/Header.vue";
export default {
  name: "LoadGoods",
  components: {
    Header,
  },
  data() {
    return {
      icon_load,
      icon_go,
      activeNames: ["1"],
      loadGoodsData: [],
      showAppList: false,
      actions: [],
      UA: "",
      /**选中的地址信息 */
      warehouseName: '',
      loadingAddress: '',
      loadingAddressLatitude: '',
      loadingAddressLongitude: ''
    };
  },
  mounted() {
    let mapList = [
      { name: "腾讯地图" },
      { name: "高德地图" },
      { name: "百度地图" },
    ];
    this.actions = mapList;
    if (navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1) {
      this.UA = "micromessenger";
      console.log("为了更好的体验，请点击右上角，选择在默认浏览器打开");
      this.$dialog.alert({
        message: "为了更好的体验，请点击右上角，选择在默认浏览器打开",
      });
    }
    orderShipListByOid({ orderId: this.$route.query.oid }).then((res) => {
      this.loadGoodsData = res.data.data;
    });
  },
  methods: {
    preview(imgGroup) {
      if (imgGroup.length > 0) {
        ImagePreview(imgGroup);
      }
    },
    OpenMap(item) {
      console.log(item);
      this.warehouseName = item.warehouseName
      this.loadingAddress = item.loadingAddress
      this.loadingAddressLatitude = item.loadingAddressLatitude
      this.loadingAddressLongitude = item.loadingAddressLongitude
      this.showAppList = true;
    },
    onSelectApp(item) {
      if (this.UA == "micromessenger") {
        this.$dialog.alert({
          message: "无法加载地图，请点击右上角，选择在默认浏览器打开",
        });
      } else {
        // 默认情况下点击选项时不会自动收起
        // 可以通过 close-on-click-action 属性开启自动收起
        this.showAppList = false;
        console.log(item.name);
        // 调用操作
        if (item.name == '腾讯地图') {
          window.location = `https://apis.map.qq.com/uri/v1/geocoder?coord=${this.loadingAddressLatitude},${this.loadingAddressLongitude}&referer=MX2BZ-Y2P33-CJZ3W-YUVUE-YVFZS-XIFN6`
        } else if (item.name == '高德地图') {
          window.location = `https://uri.amap.com/marker?position=${this.loadingAddressLongitude},${this.loadingAddressLatitude}&name=${this.warehouseName}&src=miaocang&coordinate=gaode&callnative=0`
        } else if (item.name == '百度地图') {
          window.location = `http://api.map.baidu.com/marker?location=${this.loadingAddressLatitude},${this.loadingAddressLongitude}&title=${this.warehouseName}&content=${this.warehouseName}&output=html`
        }
      }
    },
  },
};
</script>

<style lang="less">
.load {
  .van-cell__right-icon {
    position: absolute;
    right: 18px;
    bottom: 10px;
  }
  .load-item {
    .address-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.95rem;
      position: relative;
      img.icon-load {
        width: 1.25rem;
        height: auto;
        margin-right: 0.2rem;
      }
      .to-load {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #4270fa;
        position: absolute;
        right: 0;
        top: 0;
        img.icon-go {
          width: 1rem;
          height: auto;
          margin-left: 0.2rem;
          margin-right: 0.2rem;
        }
      }
    }
    .u-info {
      color: #666;
      padding-left: 1.5rem;
    }
  }
  .goods-list {
    .goods-item {
      padding-top: 1.25rem;
      border-top: 1px solid #eee;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .pic {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 0.125rem;
        margin-right: 1.25rem;
      }
      .info {
        .title {
          padding-bottom: 0.625rem;
          .name {
            font-weight: bold;
            color: #333;
          }
          .count {
            font-size: 0.75rem;
            color: #f14e67;
          }
        }
        .sku {
          font-size: 0.875rem;
          color: #869099;
        }
      }
    }
  }
  .amount {
    margin: 1.25rem 0 0.25rem;
    height: 2rem;
    line-height: 2rem;
    text-align: right;
    border-top: 1px solid #eee;
    color: #333;
  }
}
</style>